import {DatePicker, MOMENTJS_DATE_FORMAT, lastThirtyDays} from '@hconnect/uikit'
import {makeStyles} from '@material-ui/core'
import {Moment} from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'

import {PageNames} from '../../../../common/constants'
import {useFeaturesState} from '../../../../Organisms/Features'
import {AppState} from '../../../../Root.store'
import {useTrackFilterEvents} from '../../../../TrackEvents/hubFilterEvents'
import {switchView} from '../MaterialTest.action'
import {MaterialTestView} from '../MaterialTest.enum'
import {removeLookupFilter, selectDateFilter, setDateFilter} from '../MaterialTest.filters'

interface DateFilterType {
  filter: {startDate: Moment; endDate: Moment}
  setFilter: (props: {startDate: string | null; endDate: string | null}) => void
}
const useStyle = makeStyles(() => ({
  text: {height: 60}
}))
const DateFilter: React.FC<DateFilterType> = ({filter = {}, setFilter, ...props}) => {
  const classes = useStyle()
  const {trackDateFilterEvent} = useTrackFilterEvents()
  const {startDate, endDate} = filter as {startDate: Moment; endDate: Moment}
  const {t} = useTranslation()
  const {getFeature} = useFeaturesState()
  const showMaxDayMessage = getFeature('MaxDayRangeMessage')
  const showMax90DateRange = getFeature('MaxDayRange90')
  const showMax31DateRange = getFeature('MaxDayRange31')
  // TODO@igor Define quick selection here
  const getDatePickerQuickSelectionItems = (locale: string) => [
    {
      key: 'last-30-days',
      title: t('invoice.datePicker.lastThirtyDays'),
      type: 'range',
      dates: lastThirtyDays(locale)
    }
  ]

  return (
    <DatePicker
      data-test-id="materialtest-filter-datepicker"
      aria-label="material test datepicker"
      startDate={startDate}
      endDate={endDate}
      hideClearButton
      {...(showMax31DateRange && {
        maxDayRange: 31
      })}
      {...(showMax90DateRange && {
        maxDayRange: 90
      })}
      {...(showMaxDayMessage && {
        maxDayRangeInfoMessage: t('datePicker.maxDayRange')
      })}
      handleDateChange={({startDate, endDate}) => {
        setFilter({
          startDate: startDate ? startDate.toISOString() : null,
          endDate: endDate ? endDate.toISOString() : null
        })
        trackDateFilterEvent(PageNames.RMC_TEST_CERTIFICATES, {
          startDate: startDate ? startDate.format(MOMENTJS_DATE_FORMAT) : null,
          endDate: endDate ? endDate.format(MOMENTJS_DATE_FORMAT) : null
        })
      }}
      getQuickSelectionItems={getDatePickerQuickSelectionItems}
      textClasses={classes.text}
      {...props}
    />
  )
}
const mapStateToProps = (state: AppState) => ({
  filter: selectDateFilter(state)
})
const mapDispatchToProps = (dispatch) => ({
  setFilter: (props) => {
    dispatch(
      switchView({
        view: MaterialTestView.DEFAULT,
        clearPrevView: true
      })
    )
    dispatch(removeLookupFilter())
    dispatch(setDateFilter(props))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(DateFilter)
